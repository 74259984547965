

















































import JobApi from '@/services/api/JobApi';
import ResumeApi from '@/services/api/ResumeApi';
import JobInterface from '@/interfaces/JobInterface';
import { Component, Watch } from 'vue-property-decorator';
import ResumeInterface from '@/interfaces/ResumeInterface';
import EditButton from '@/components/common/EditButton.vue';
import ExampleInterface from '@/interfaces/ExampleInterface';
import BaseCrudDialog from '@/components/base/BaseCrudDialog';
import CreateButton from '@/components/common/CreateButton.vue';
import RemoveButton from '@/components/common/RemoveButton.vue';

@Component({
    components: {
        EditButton,
        CreateButton,
        RemoveButton
    }
})
export default class ExampleDialog extends BaseCrudDialog {
    public entityType: string = 'example';
    public entity: ExampleInterface = {} as ExampleInterface;
    public resumes: ResumeInterface[] = [];
    public jobs: JobInterface[] = [];

    @Watch('dialog')
    public onDialogChanged(): void {
        if (this.dialog) {
            this.getJobs();
            this.getAdminResumes();
        }
    }

    public async getJobs(): Promise<void> {
        try {
            const response = await (new JobApi()).getAllJobs();

            this.jobs = response.data.items;
        } catch (e) {
            console.error(e);
        }
    }

    public async getAdminResumes(): Promise<void> {
        try {
            const response = await (new ResumeApi()).getAdminResumes();

            this.resumes = response.data.items;
        } catch (e) {
            console.error(e);
        }
    }
}
