import Vue from 'vue';
import { AxiosPromise } from 'axios';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Actions extends Vue {
    @Prop({ required: true, type: Object })
    // eslint-disable-next-line
    public apiClient!: any;

    // eslint-disable-next-line
    public render() {
        if (this.$scopedSlots.default) {
            return this.$scopedSlots.default({
                edit: this.edit,
                create: this.create,
                update: this.update,
                destroy: this.destroy,
                defaultEntity: this.getDefaultEntity
            });
        }
    }

    public getDefaultEntity(): AxiosPromise {
        return this.apiClient.getDefaultEntity();
    }

    public edit(id: number): AxiosPromise {
        return this.apiClient.getEntityById(id);
    }

    public create(data: FormData): AxiosPromise {
        return this.apiClient.create(data);
    }

    public update(id: number, data: FormData): AxiosPromise {
        return this.apiClient.update(id, data);
    }

    public destroy(id: number): AxiosPromise {
        return this.apiClient.destroy(id);
    }
}
