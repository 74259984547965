
















































import { DataTableHeader } from 'vuetify';
import BaseList from '@/components/base/BaseList';
import { Component } from 'vue-property-decorator';
import ExampleApi from '@/services/api/ExampleApi';
import Actions from '@/components/renderless/Actions';
import JobDialog from '@/components/jobs/JobDialog.vue';
import ExampleDialog from '@/components/examples/ExampleDialog.vue';

@Component({
    components: {
        Actions,
        JobDialog,
        ExampleDialog
    }
})
export default class ExampleList extends BaseList {
    public apiClient = (new ExampleApi());
    public headers: DataTableHeader[] = [
        { text: '№', value: 'id' },
        { text: 'Resume', value: 'resume.title', sortable: false },
        { text: 'Job', value: 'job.name', sortable: false },
        { text: '', value: 'actions', align: 'end', sortable: false }
    ];
}
