



































































import JobInterface from '@/interfaces/JobInterface';
import CategoryApi from '@/services/api/CategoryApi';
import { Component, Watch } from 'vue-property-decorator';
import EditButton from '@/components/common/EditButton.vue';
import BaseCrudDialog from '@/components/base/BaseCrudDialog';
import CategoryInterface from '@/interfaces/CategoryInterface';
import CreateButton from '@/components/common/CreateButton.vue';
import RemoveButton from '@/components/common/RemoveButton.vue';

@Component({
    components: {
        EditButton,
        CreateButton,
        RemoveButton
    }
})
export default class JobDialog extends BaseCrudDialog {
    public entityType: string = 'job';
    public entity: JobInterface = {} as JobInterface;
    public categories: CategoryInterface[] = [];

    @Watch('dialog')
    public onDialogChanged(): void {
        if (this.dialog) {
            this.getCategories();
        }
    }

    public async getCategories(): Promise<void> {
        try {
            const response = await (new CategoryApi()).getAllCategories();

            this.categories = response.data.categories;
        } catch (e) {
            console.error(e);
        }
    }
}
