













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CreateButton extends Vue {
    @Prop({ required: false, type: String, default: () => 'mdi-plus' })
    public icon!: string;
    @Prop({ required: true, type: String })
    public text!: string;

    public getDefaultEntity(): void {
        this.$emit('getDefaultEntity');
    }
}
