import { AxiosPromise } from 'axios';
import BaseApi from '@/services/api/BaseApi';
import HttpAdapter from '@/adapters/HttpAdapter';

export default class CategoryApi extends BaseApi {
    protected type: string = 'categories';

    public getAllCategories(): AxiosPromise {
        return HttpAdapter.get(`${this.uri}/api/admin/${this.type}/all`);
    }
}
