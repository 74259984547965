/* eslint-disable */

export default class QueryParamsUtil {
    public static objectToQueryParamsString(obj: any): string {
        return Object.keys(obj)
            .filter(key => obj[key] !== null && obj[key] !== '')
            .map(key => `${key}=${obj[key]}`).join('&');
    }

    public static getQueryParamValueByName(param: string): string|null {
        const urlParams = new URLSearchParams(window.location.search);

        return urlParams.get(param);
    }
}
