import { AxiosPromise } from 'axios';
import BaseApi from '@/services/api/BaseApi';
import HttpAdapter from '@/adapters/HttpAdapter';
import QueryParamsUtil from '@/utils/QueryParamsUtil';

export default class ResumeApi extends BaseApi {
    protected type: string = 'resumes';

    public constructor(entity: string = 'resume') {
        super();

        if (entity === 'cover-letter') {
            this.type = 'cover-letters';
        }
    }

    public getAdminResumes(): AxiosPromise {
        return HttpAdapter.get(`${this.uri}/api/admin/${this.type}/admin`);
    }

    public getItems(
        page: number,
        perPage: number,
        filters: Record<string, string | null>,
        sortBy: string = '',
        sortDir: string = 'asc'
    ): AxiosPromise {
        let endpoint = `/api/admin/${this.type}?page=${page}&per_page=${perPage}`;
        const userId = QueryParamsUtil.getQueryParamValueByName('user_id');

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                endpoint += `&${key}=${filters[key]}`;
            }
        });

        if (userId) {
            endpoint += `&user_id=${userId}`;
        }

        if (sortBy) {
            endpoint += `&sort=${sortBy}&dir=${sortDir}`;
        }

        return HttpAdapter.get(`${this.uri}${endpoint}`);
    }
}
