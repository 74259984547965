





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class RemoveButton extends Vue {
    @Prop({ required: false, type: String, default: () => 'mdi-cancel' })
    public icon!: string;

    public dialog: boolean = false;

    public close(): void {
        this.dialog = false;
    }

    public remove(): void {
        this.$emit('remove');

        this.close();
    }
}
