var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Examples ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('lazy-search',{on:{"input":function($event){return _vm.getItems(true)}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"md":"10"}},[_c('actions',{attrs:{"api-client":_vm.apiClient},on:{"refresh":_vm.getItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var create = ref.create;
var defaultEntity = ref.defaultEntity;
return [_c('example-dialog',{attrs:{"create":create,"default-entity":defaultEntity}})]}}])})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:options":[function($event){_vm.options=$event},_vm.optionChanged]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('actions',{attrs:{"api-client":_vm.apiClient},on:{"refresh":_vm.getItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var edit = ref.edit;
var update = ref.update;
var destroy = ref.destroy;
return [_c('example-dialog',{attrs:{"id":item.id,"edit":edit,"update":update,"destroy":destroy}})]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }