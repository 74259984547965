import { AxiosPromise } from 'axios';
import BaseApi from '@/services/api/BaseApi';
import HttpAdapter from '@/adapters/HttpAdapter';

export default class JobApi extends BaseApi {
    protected type: string = 'jobs';

    public getAllJobs(): AxiosPromise {
        return HttpAdapter.get(`${this.uri}/api/admin/${this.type}/all`);
    }
}
